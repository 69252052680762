import { Tab, Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Info from "./sections/Info";
import bgImage from "../assets/images/gradient-right-dark.svg";
import bgImage2 from "../assets/images/gradient-right-dark.svg";
import Requirements from "./sections/Requirements";
import { useTheme } from '@mui/material/styles';
import Footer from "../components/Footer";
import * as React from "react";
import { useState, useEffect } from "react"
import Table from "./sections/Table";
import NextUINav from "../components/Navbars/Navbar";

function Leaderboards() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    const theme = useTheme();
    return (
        <>
        <NextUINav />
            <Box
            minHeight="75vh"
            width="100%"
            sx={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
                display: "grid",
                placeItems: "center",
            }}
            >
                <Container>
                    <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                        <Typography
                        variant="h1"
                        mt={-6}
                        mb={2}
                        >
                        </Typography>
                        <Typography
                        variant="body1"
                        textAlign="center"
                        mt={1}
                        >
                            Ranked Leaderboards <br />
                            Top 10
                        </Typography>
                    </Grid>
                    <Table />
                </Container>
            </Box>
            <Box pt={6} px={1} mt={6} sx={{ backgroundImage: `url(${bgImage2})`, backgroundRepeat: "no-repeat"}}>
                <Footer />
            </Box>
        </>
    )
}

export default Leaderboards;