import { Navbar, Dropdown, Button, Link, Text } from "@nextui-org/react";
import { Layout } from "./Layout.js";
import { icons } from "../Icons.js";
import Login from "../Modal/Login.js";

export default function DefaultNavbar() {
    const collapseItems = [
        "Home",
        "Live",
        "Map Com",
        "Developments",
        "Leaderboards",
        "Downloads",
    ];

  return (
    <Layout>
      <Navbar isBordered variant="sticky">
      <Navbar.Toggle showIn="xs" />
        <Navbar.Brand>
          <Text b color="inherit" hideIn="xs">

          </Text>
        </Navbar.Brand>
        <Navbar.Content
          activeColor="secondary"
          hideIn="xs"
          variant="underline"
        >
          <Navbar.Link isActive href="/home">Home</Navbar.Link>
          <Navbar.Link href="/liveservers">
            Live
          </Navbar.Link>
          <Navbar.Link href="/mapcommunity">Map Com</Navbar.Link>
          <Navbar.Link href="/developments">Developments</Navbar.Link>
          <Navbar.Link href="/leaderboards">Leaderboards</Navbar.Link>
          <Dropdown isBordered>
            <Navbar.Item>
              <Dropdown.Button
                auto
                light
                css={{
                  px: 0,
                  dflex: "center",
                  svg: { pe: "none" },
                }}
                iconRight={icons.chevron}
                ripple={false}
              >
                Downloads
              </Dropdown.Button>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label="ACME features"
              css={{
                $$dropdownMenuWidth: "340px",
                $$dropdownItemHeight: "70px",
                "& .nextui-dropdown-item": {
                  py: "$4",
                  // dropdown item left icon
                  svg: {
                    color: "$secondary",
                    mr: "$4",
                  },
                  // dropdown item title
                  "& .nextui-dropdown-item-content": {
                    w: "100%",
                    fontWeight: "$semibold",
                  },
                },
              }}
            >
              <Dropdown.Item
                key="autoscaling"
                showFullDescription
                description="Provided by FEARIV"
                icon={icons.server}
                href="#"
              >
                Dedicated Server Launcher
              </Dropdown.Item>
              <Dropdown.Item
                key="usage_metrics"
                showFullDescription
                description="Provided by Janne252"
                icon={icons.activity}
                href="#"
              >
                Map Editor Mod
              </Dropdown.Item>
              <Dropdown.Item
                key="production_ready"
                showFullDescription
                description="Required to join Ranked Matches"
                icon={icons.flash}
                href="#"
              >
                PunkBuster
              </Dropdown.Item>
              <Dropdown.Item
                key="99_uptime"
                showFullDescription
                description="Fixes custom map download issues"
                icon={icons.server}
                href="#"
              >
                winhttp.dll
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Content>
        <Navbar.Content>
          <Navbar.Link color="inherit">
          <Login />
          </Navbar.Link>
          <Navbar.Item>
            <Button auto flat as={Link} href="sign-up">
              Sign Up
            </Button>
          </Navbar.Item>
        </Navbar.Content>
        {/* Navbar.Collapse for mobile view */}
        <Navbar.Collapse>
          {collapseItems.map((item, index) => (
            <Navbar.CollapseItem
              key={item}
              activeColor="secondary"
              css={{
                color: index === collapseItems.length - 1 ? "$error" : "",
              }}
              isActive={index === 0} // Set the active item here
            >
              <Link color="inherit" href="#">
                {item}
              </Link>
            </Navbar.CollapseItem>
          ))}
        </Navbar.Collapse>
      </Navbar>
    </Layout>
  );
}