import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState, useEffect } from "react";

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
    const [data, setData] = useState([]); /* <- COMBAT VETERANS DATA */
    const [data2, setData2] = useState([]); /* <- DEADLIEST FIGHTERS DATA */
    const [data3, setData3] = useState([]); /* <- WILL TO WIN DATA */
    const [data4, setData4] = useState([]); /* <- DM MASTERS DATA */
    const [data5, setData5] = useState([]); /* <- TDM MASTERS DATA */
    const [data6, setData6] = useState([]); /* <- CTD MASTERS DATA */
    const [data7, setData7] = useState([]); /* <- UPR MASTERS DATA */

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.farcry2.online/pc/stats/leaderboards/combat_veterans?rowCount=10');
        const jsonData = await response.json();
        console.log(jsonData);
        setData(jsonData.entries);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.farcry2.online/pc/stats/leaderboards/deadliest_fighters?rowCount=10');
        const jsonData = await response.json();
        console.log(jsonData);
        setData2(jsonData.entries);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.farcry2.online/pc/stats/leaderboards/the_will_to_win?rowCount=10');
        const jsonData = await response.json();
        console.log(jsonData);
        setData3(jsonData.entries);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.farcry2.online/pc/stats/leaderboards/deathmatch_masters?rowCount=10');
        const jsonData = await response.json();
        console.log(jsonData);
        setData4(jsonData.entries);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.farcry2.online/pc/stats/leaderboards/team_deathmatch_masters?rowCount=10');
        const jsonData = await response.json();
        console.log(jsonData);
        setData5(jsonData.entries);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.farcry2.online/pc/stats/leaderboards/capture_the_diamond_masters?rowCount=10');
        const jsonData = await response.json();
        console.log(jsonData);
        setData6(jsonData.entries);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.farcry2.online/pc/stats/leaderboards/uprising_masters?rowCount=10');
        const jsonData = await response.json();
        console.log(jsonData);
        setData7(jsonData.entries);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Position</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((entry) => (
                    <TableRow>
                        <TableCell>{entry.row_id}</TableCell>
                        <TableCell>{entry.username}</TableCell>
                        <TableCell>{entry.score}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Combat Veterans', 159, 6.0, 24, 4.0, 3.99),
  createData('Deadliest Fighters', 237, 9.0, 37, 4.3, 4.99),
  createData('The Will to Win', 262, 16.0, 24, 6.0, 3.79),
  createData('Deathmatch Masters', 305, 3.7, 67, 4.3, 2.5),
  createData('Team Deathmatch Masters', 356, 16.0, 49, 3.9, 1.5),
  createData('Capture the Diamond Masters', 356, 16.0, 49, 3.9, 1.5),
  createData('Uprising Masters', 356, 16.0, 49, 3.9, 1.5),
];

export default function CollapsibleTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}