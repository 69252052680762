import PropTypes from "prop-types";
import { Box, Typography, Container, Link, Icon } from "@mui/material";
import typography from "../assets/theme/base/typography";
import { useTheme } from "@mui/material/styles";

function MinimalFooter({ links, light }) {
    const theme = useTheme();
    const {size } = typography;

    const renderLinks = () =>
    links.map((link, key) => (
        <Box
        key={link.name}
        component="li"
        pl={key === 0 ? 0 : 2}
        pr={key === links.length - 1 ? 0 : 2}
        lineHeight={1}
        >
            <Link href={link.href} target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? "white" : "text"}>
                    {link.name}
                </Typography>
            </Link>
        </Box>
    ));

    return (
        <Container>
            <Box
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
            >
                <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                fontSize={size.sm}
                >
                    &copy; {new Date().getFullYear()}, The FC2 Community
                </Box>
            </Box>
            <Box
            component="ul"
            sx={({ breakpoints }) => ({
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                listStyle: "none",
                mt: 3,
                mb: 0,
                p: 0,
                [breakpoints.up("lg")]: {
                    mt: 0,
                }
            })}
            >
            </Box>
        </Container>
    );
}

MinimalFooter.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape),
    light: PropTypes.bool,
};

export default MinimalFooter;