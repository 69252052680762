import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Input, Typography, Card, Switch, Grid } from "@mui/material";
import MuiLink from "@mui/material/Link";
import GithubIcon from "@mui/icons-material/GitHub";
import BsDiscord from "react-icons/bs";
import bgImage from "../../assets/images/gradient-right-dark.svg";
import MinimalFooter from "../../components/MinimalFooter";
import { Popover, Text } from "@nextui-org/react";
import NextUINav from "../../components/Navbars/Navbar";

function SignUp() {
    const [rememberMe, setRememberMe] = useState(false);
    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    return (
        <>
        <NextUINav />
        <Box
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: {bgImage},
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <Box
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <Typography variant="h4" fontWeight="medium" color="primary" mt={1}> <br />
                  Sign up
                </Typography>
                <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                  <Grid item xs={2}>
                    <Typography component={MuiLink} href="#" variant="body1" color="primary">
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box pt={4} pb={3} px={3}>
                <Box component="form" role="form">
                  <Box mb={2}> Username <Popover isBordered>
                      <Popover.Trigger>
                        <Button animated={false} auto flat color="primary">?</Button>
                      </Popover.Trigger>
                      <Popover.Content>
                        <Text css={{ p: "$5" }}>Upper/Lowercase letters ( a - z )</Text>
                        <Text css={{ p: "$5" }}>Numbers ( 0 - 9 )</Text>
                        <Text css={{ p: "$5" }}>Cannot begin with special characters</Text>
                        <Text css={{ p: "$5" }}>Usable special characters ( _ . - )</Text>
                        <Text css={{ p: "$5" }}>Minimum length of 3</Text>
                        <Text css={{ p: "$5" }}>Maximum length of 15</Text>
                      </Popover.Content>
                    </Popover>
                    <Input type="username" label="Username" fullWidth required />
                  </Box>
                  <Box mb={2}> Email
                    <Input type="email" label="Email" fullWidth required />
                  </Box>
                  <Box mb={2}> Password
                    <Input type="password" label="Password" fullWidth required />
                  </Box>
                  <Box mb={2}> Confirm Password
                    <Input type="password" label="Password" fullWidth required />
                  </Box>
                  <Box display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <Typography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;I agree to Terms of Services
                    </Typography>
                  </Box>
                  <Box mt={4} mb={1}>
                    <Button variant="gradient" color="info" fullWidth>
                      Create account
                    </Button>
                  </Box>
                  <Box mt={3} mb={1} textAlign="center">
                    <Typography variant="button" color="text">
                      Already have an account?{" "}
                      <Typography
                        component={Link}
                        to="/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign in
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <MinimalFooter light />
      </Box>
    </>
  );
}

export default SignUp;