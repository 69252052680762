import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Input, Typography, Card, Switch, Grid } from "@mui/material";
import MuiLink from "@mui/material/Link";
import GithubIcon from "@mui/icons-material/GitHub";
import BsDiscord from "react-icons/bs";
import bgImage from "../assets/images/bluewallpaper.png";
import MinimalFooter from "../components/MinimalFooter";
import { useTheme } from "@mui/material/styles";
import NextUINav from "../components/Navbars/Navbar";

function SignIn() {
    const theme = useTheme();
    const [rememberMe, setRememberMe] = useState(false);
    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    return (
        <>
        <NextUINav />
        <Box
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <Box
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <Typography variant="h4" fontWeight="medium" color="primary" mt={1}> <br />
                  Sign in
                </Typography>
                <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                  <Grid item xs={2}>
                    <Typography component={MuiLink} href="#" variant="body1" color="white">
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box pt={1} pb={4} px={5}>
                <Box component="form" role="form">
                  <Box mb={2} fontSize={17}>Email
                    <Input type="email" label="Email" fullWidth />
                  </Box>
                  <Box mb={2} fontSize={17}>Password
                    <Input type="password" label="Password" fullWidth />
                  </Box>
                  <Box display="flex" alignItems="center" ml={-1}>
                    <Switch
                    checked={rememberMe}
                    onChange={handleSetRememberMe}
                    />
                    <Typography
                      variant="button"
                      fontWeight="regular"
                      color={"text"}
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </Typography>
                  </Box>
                  <Box mt={4} mb={1}>
                    <Button color="info">
                      sign in
                    </Button>
                  </Box>
                  <Box mt={3} mb={1} textAlign="center">
                    <Typography variant="button" color="info">
                      Don&apos;t have an account?{" "}
                      <Typography
                        component={Link}
                        to="/sign-up"
                        variant="button"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign up
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <MinimalFooter light />
      </Box>
    </>
  );
}

export default SignIn;