import { Box, Card, Container, Typography } from "@mui/material";
import { Button, Grid, useTheme } from "@nextui-org/react";
import bgImage from "../assets/images/gradient-right-dark.svg";
import bgImage2 from "../assets/images/color-sharp.png";
import Footer from "../components/Footer";
import NextUINav from "../components/Navbars/Navbar";

function Home() {
    const theme = useTheme();
    return (
        <>
        <NextUINav />
            <Box
            minHeight="75vh"
            width="100%"
            sx={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
                display: "grid",
                placeItems: "center",
            }}
            >
                <Container>
                    <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                        <Typography
                        variant="h3"
                        mt={-6}
                        mb={4}
                        textAlign="center"
                        >
                            Welcome to the
                        </Typography>
                        <Typography
                        textAlign="center"
                        mt={1}
                        >
                            Far Cry 2 Community Backend Services. <br />
                            We offer a community-made utility for patching Far Cry 2 to yet again support Online Multiplayer, + more on this page!<br /> <br />
                        </Typography>
                        <Typography>
                            <Button bordered color="primary" auto>
                                Download Patch
                            </Button>
                            <Button bordered color="gradient" auto>
                                Github
                            </Button>
                        </Typography>
                    </Grid>
                </Container>
            </Box>
            <Box pt={6} px={1} mt={6} sx={{ backgroundImage: `url(${bgImage2})`, backgroundRepeat: "no-repeat"}}>
                <Footer />
            </Box>
        </>
    )
}

export default Home;