import './App.css';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@nextui-org/react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/sections/SignUp";
import Leaderboards from './pages/Leaderboards';
import { NextUIProvider, createTheme, useTheme } from '@nextui-org/react';

const theme = createTheme({
  type: "dark"
});

function App() {
  return (
    <NextUIProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="*" element={<Navigate to="/home" />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="/leaderboards" element={<Leaderboards />} />
      </Routes>
    </NextUIProvider>
  );
}

export default App;
